import {Link, useRouteError} from "react-router-dom";
import {useEffect} from "react";

export default function ErrorBoundary() {
    let error = useRouteError();

    useEffect(() => {
        if (error.status === 404) {
            document.title = "404 - Page not found";
        } else {
            document.title = "Error";
        }
    }, []);


    return (
        <main className="grid h-screen min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-indigo-600">{error.status}</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    {error.status === 404 ? (
                        <span>Page not found</span>
                    ) : (
                        <span>Error</span>
                    )}
                </h1>
                <p className="mt-6 text-base leading-7 text-gray-600">
                    {error.status === 404 ? (
                        <span className="block">Sorry, we couldn’t find the page you’re looking for.</span>
                    ) : (
                        <span className="block">An error has occurred</span>
                    )}
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link
                        to="/"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Go back home
                    </Link>
                </div>
            </div>
        </main>
    );
}